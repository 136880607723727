@font-face {
    font-family: 'Unibet';
    font-weight: 100;
    src: url('./assets/UnibetPro.woff') format('woff');
}

@font-face {
    font-family: 'Roboto', sans-serif;
}

* {
    box-sizing: border-box;
}

.template {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    z-index: 100;
}

html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

#root>header {
    background: #F3F3F4;
    padding: 12px 12px 0 12px;
    font-weight: bold;
}

.App {
    border: 12px solid #F3F3F4;
}

.App footer {
    background: #F3F3F4;
    border-top: 12px solid #F3F3F4;
    display: flex;
    justify-content: space-between;
}

.App footer img {
    height: 21px;
}

main {
    border: 1px solid #D1D1D2;
}

main header {
    font-size: 3.5vw;
    font-weight: 600;
    text-align: center;
    height: 9.3vw;
    line-height: 9.3vw;
    background: #102049;
    color: #fff;
    border-bottom: 0.9vw solid #F9D749;
}

main header .back {
    position: absolute;
    left: 5px;
    font-size: 2em;
}

.match {
    border-bottom: 1px solid #D1D1D2;
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.match .teams {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Lato;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.match .teams .team {
    display: flex;
    align-items: center;
}

.match .teams .team .team-logo {
    width: 20px;
    height: 20px;
    margin: 4px 4px 4px 0;
}

.match .teams .team .team-code {
    display: block;
}

.match .teams .team .team-name {
    display: none;    
}

.match .bets {
    display: flex;
    justify-content: space-between;
}

.match .bets .bet {
    display: flex;
    flex-direction: column;
    flex-basis: 1;
    flex-grow: 1;
    margin: 0 2px;
    width: 84px;
    font-family: Oswald;
    font-weight: 700;
    font-size: 14px;
}

.match .bets .bet .bet-button {
    display: flex;
}

.match .bets .bet .bet-button .bet-type {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 28px;
    line-height: 28px;
    background: #767679;
    color: #C8C8C9;
}

.match .bets .bet .bet-button .bet-odds {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 28px;
    line-height: 28px;
    background: #A3A3A5;
    color: #fff;
}

.match .bets .bet .bb {
    display: flex;
    align-items: center;
    height: 28px;
}

.match .bets .bet .bb .bb-amount {
    width: 27px;
    font-size: 14px;
    color: #A3A3A5;
}

.match .bets .bet .bb .bb-bar-container {
    width: 57px;
}

.match .bets .bet .bb .bb-bar {
    margin: 2px;
    height: 2px;
    background: #FE5621;
}

.match .bets .bet .bb .bb-bar {
    background: #FE5621;
}

.match .bets .bet.active .bet-button .bet-type {
    background: #005D21;
    color: #A0BDA8;
}

.match .bets .bet.active .bet-button .bet-odds {
    background: #007F2D;
}

.match .bets .bet.active .bb .bb-bar {
    background: #00BD07;
}


.total {
    display: flex;
    align-items: center;
    padding: 12px;
}

.total .total-left {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.total .total-label {
    font-weight: 700;
    font-family: Lato;
    font-size: 18px;
}


.total .total-right {
    width: 264px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.total .total-odds {
    text-align: center;
    color: #fff;
    background-color: #007F2D;
    border: 0;
    border-radius: 2px;
    width: 106px;
    height: 36px;
    line-height: 36px;
    font-family: Oswald;
    font-size: 14px;
    font-weight: 700;
}

.total .place-bets {
    color: #fff;
    background-color: #008DFF;
    border: 0;
    border-radius: 2px;
    width: 142px;
    height: 36px;
    line-height: 28px;
    font-size: 16px;
    font-family: Lato;
    font-weight: 700;
}

.total .place-bets:disabled {
    opacity: 0.6;
}

.loading {
    width: 100%;
    height: 100%;
    font-family: Unibet;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}


button {
    cursor: pointer;
}

button:disabled {
    cursor: initial;
}

@media screen and (min-width: 512px) {
   .match .teams .team .team-code {
        display: none;
    }

    .match .teams .team .team-name {
        display: block;
    }
}

@media screen and (max-width: 400px) {
    .match .bets .bet {
        width: 64px;
    }

    .total .total-right {
        width: 204px;
    }

    .total .total-odds {
        width: 86px;
    }

    .total .place-bets {
        width: 112px;
    }
}